.history-title {
  height: 2.5rem;
}

.doc-explorer-title {
  height: 2.5rem;
}

.topBar {
  height: unset !important;
}

.docExplorerResizer {
  display: none;
}
